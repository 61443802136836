<template>
    <div>
        <div class="" :class="{'loading' : isLoading, 'heatmap-camera-container': listCamera.length > 0 }">
            <div class="gradientStyles" style="padding-top: 1rem">
                <span ref="min-layout" id="min-layout" style="margin: 3px"></span>
                <img ref="gradient-layout" id="gradient-layout" src="" style="width: 16rem" alt=""/>
                <span ref="max-layout" id="max-layout" style="margin: 3px"></span>
            </div>
            <div
                class="heatmap-container"
                style="width: 680px; height: auto"
                ref="heatmapWrapper"
            >
                <div
                    class="tooltip"
                    ref="tooltip"
                    style="
                    position: absolute;
                    left: 0;
                    top: 0;
                    background: rgba(0, 0, 0, 0.8);
                    color: white;
                    font-size: 14px;
                    padding: 5px;
                    line-height: 18px;
                    z-index: 9;
                    display: none;
                  "
                ></div>
                <span v-if="listCamera.length === 0">{{ $t('Heatmap.HeatmapNotFound') }}</span>
                <img
                    v-if="listCamera.length > 0"
                    :src="image"
                    alt=" "
                    style="width: 100%; height: 480px"
                    @load="drawHeatmap"

                />
                <div 
                    id="gradient-heatmap-camera" 
                    class="gradient-heatmap-camera" 
                    style="padding-top: 1rem"
                    v-if="listCamera.length !== 0"
                >
                    <span :id="'min-camera'" style="margin: 3px"></span>
                    <img :id="'gradient-camera'" src="" style="width: 16rem" alt=""/>
                    <span :id="'max-camera'" style="margin: 3px"></span>
                </div>
            </div>

            <div class="camera-selection">
                <div
                    class="camera-item"
                    v-for="(camera, index) in listCamera"
                    :key="camera['cameraID']"
                    :class="{'active': camera['cameraID'] === selectedCamera['cameraID'], 'loading': isLoading}"
                    @click="handleSelectCamera(camera['cameraID'])"
                >
                    <div
                        class="tooltip-image"
                        ref="tooltipImages"
                        v-show="camera['cameraID'] === previewCameraID"
                        v-click-outside="handleClickClosePreviewCamera"
                        @click="handleClickClosePreviewCamera"
                    >
                        <img :src="camera['url']" alt="tooltip"/>
                        <div
                            class="close-button"
                            @click.stop="handleClickClosePreviewCamera"
                        >
                        <span
                            class="material-icons"
                            style="
                                color: #FFFFFF; 
                                display: inline-block; 
                                vertical-align: middle; 
                                line-height: normal
                            "
                        >
                            close
                        </span>
                        </div>
                    </div>
                    <div class="camera-select-item">
                    <span
                        class="material-icons margin-left-10"
                        v-show="camera['cameraID'] === selectedCamera['cameraID']"
                        style="color: #276EFF;"
                    >
                        check_circle
                    </span>
                        <span
                            class="material-icons margin-left-10"
                            style="color: #8C8C8C; font-size: 20px;"
                            v-show="camera['cameraID'] !== selectedCamera['cameraID']"
                        >
                        radio_button_unchecked
                    </span>
                        <span class="camera-name margin-left-6">{{ camera["cameraName"] }}</span>
                        <span
                            class="material-icons margin-right-12px"
                            style="color: #D9D9D9; font-size: 20px;"
                            @click.stop="handleClickInfoCamera(camera['cameraID'], index)"
                        >
                        info
                    </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import h337 from "heatmap.js";
import {Api} from "@/api";

const reportHeatMapByCameraAndDate = Api.get("getReportHeatmapByCameraAndDate");

export default {
    name: "HeatmapCameraZoneAnalysis",
    props: {
        heatmapCameraQuery: {
            type: Object
        },
    },
    mounted() {
        if (navigator.userAgent.indexOf("Chrome") !== -1) {
            this.isChrome = true;
        }
        if (this.heatmapCameraQuery.listCamera !== undefined) {
            this.listCamera = this.heatmapCameraQuery["listCamera"]
            this.selectedCamera = this.heatmapCameraQuery["listCamera"][0]
            this.dateRange = this.heatmapCameraQuery["dateRange"]
            this.processHeatmapByCameraID(this.heatmapCameraQuery.listCamera[0].cameraID, this.heatmapCameraQuery["dateRange"]["dateStart"], this.heatmapCameraQuery["dateRange"]["dateEnd"])
        }
    },
    data() {
        return {
            isChrome: false,
            image: "",
            max: 10,
            heatmapData: [],
            heatmapWidth: 680,
            heatmapHeight: 480,
            listCamera: [],
            dateRange: [],
            selectedCamera: {},
            previewCameraID: "",
            gradientConfig: {
                1: "rgb(255,0,0)",
                0.25: "rgb(0,0,255)",
                0.55: "rgb(0,255,0)",
                0.85: "yellow"
            },
            isLoading: false,
        }
    },
    watch: {
        heatmapCameraQuery(heatmapCameraQuery) {
            this.listCamera = heatmapCameraQuery["listCamera"]

            if (this.listCamera && this.listCamera.length > 0) {
                this.selectedCamera = heatmapCameraQuery["listCamera"][0]
            }
            this.dateRange = heatmapCameraQuery["dateRange"]

            this.processHeatmapByCameraID(this.selectedCamera["cameraID"], this.dateRange["dateStart"], this.dateRange["dateEnd"])
        }
    },
    methods: {
        handleClickInfoCamera(cameraID, cameraIndex) {
            if (cameraIndex < 5) {
                this.$refs.tooltipImages[cameraIndex].style.top = "105%";
                this.$refs.tooltipImages[cameraIndex].style.right = "0";
                this.$refs.tooltipImages[cameraIndex].style.zIndex = 99;
            }

            this.previewCameraID = cameraID
        },
        // updateHeatmapLegend(max, min) {
        //     let legendCanvas = document.createElement("canvas");
        //     legendCanvas.width = 100;
        //     legendCanvas.height = 10;

        //     let legendCtx = legendCanvas.getContext("2d");
        //     let gradientCfg = {};
            
        //     this.$refs["min-layout"].innerHTML = min + "s";
        //     this.$refs["max-layout"].innerHTML = max + "s";

        //     gradientCfg = this.gradientConfig;
        //     let gradient = legendCtx.createLinearGradient(0, 0, 100, 1);
        //     gradient.addColorStop(0.05, "white")
        //     for (let key in gradientCfg) {
        //         gradient.addColorStop(key - 0.1, gradientCfg[key]);
        //     }

        //     legendCtx.fillStyle = gradient;
        //     legendCtx.fillRect(0, 0, 100, 10);

        //     this.$refs["gradient-layout"].src = legendCanvas.toDataURL();
        // },
        handleSelectCamera(cameraID) {
            this.isLoading = true
            this.selectedCamera = this.listCamera.find(camera => camera["cameraID"] === cameraID)
            this.processHeatmapByCameraID(this.selectedCamera["cameraID"], this.dateRange["dateStart"], this.dateRange["dateEnd"])
        },
        handleClickClosePreviewCamera() {
            this.previewCameraID = ""
        },
        processHeatmapByCameraID(cameraID, dateStart, dateEnd) {
            this.removeOldCanvas()
            this.getHeatMapData(cameraID, dateStart, dateEnd)
        },
        removeOldCanvas() {
            let heatmapWrapper = this.$refs.heatmapWrapper;
            try {
                if (this.$refs.heatmapWrapper !== undefined) {
                    this.$refs.heatmapWrapper.childNodes.forEach((childNode, key) => {
                        if (key >= 4) {
                            heatmapWrapper.removeChild(childNode)
                        }
                    })
                }
            } catch (err) {
            }

        },
        drawHeatmap() {
            this.removeOldCanvas()
            let heatMapElement = this.$refs.heatmapWrapper;
            let heatmapInstance = h337.create({
                container: heatMapElement,
                maxOpacity: 0.5,
                radius: 14,
                blur: 0.9
            });

            let heatmapData = {
                max: this.max,
                data: this.heatmapData,
                min: 0
            }

            heatmapInstance.setData(heatmapData)

            this.displayTooltip(heatmapInstance);
            this.updateHeatmapLegend(this.max, 0)
        },
        displayTooltip(heatmapInstance) {
            const canvasElement = this.$refs.heatmapWrapper;
            let self = this;
            let tooltip = this.$refs.tooltip;
            canvasElement.onmousemove = function (ev) {
                let additionalX = self.calculateXValue()
                let x = ev.layerX;
                let y = ev.layerY;

                let value = heatmapInstance.getValueAt({
                    x: x,
                    y: y
                });

                self.updateTooltip(x, y, value, additionalX, tooltip);
            };

            canvasElement.onmouseout = function () {
                tooltip.style.display = "none";
            };
        },
        updateTooltip(x, y, value, additionalX, tooltip) {
            let colorValue = parseInt((255 * value) / (this.max + 1), 10)

            if (colorValue <= 8) {
                value = 0
            }

            value = new Intl.NumberFormat().format(value)

            tooltip.style.display = "block";
            tooltip.style.position = "absolute";
            tooltip.style.left = x + additionalX + "px";
            tooltip.style.top = y + 20 + "px";
            tooltip.innerHTML = value + " s";
        },
        calculateXValue() {
            let screenSize = window.innerWidth;
            if (screenSize === 1920) {
                return 30
            }

            return (screenSize * 30) / 1920
        },
        getHeatMapData(cameraID, dateStart, dateEnd) {
            let self = this

            reportHeatMapByCameraAndDate.get(cameraID, dateStart, dateEnd, "false")
                .then(response => {
                    self.image = response.data.data.url
                    self.max = response.data.data.max
                    self.heatmapData = response.data.data.data
                    self.isLoading = false
                })
        },
        updateHeatmapLegend(max, min) {
            let legendCanvas = document.createElement("canvas");
            legendCanvas.width = 100;
            legendCanvas.height = 10;

            let legendCtx = legendCanvas.getContext("2d");
            let gradientCfg = {};
            max = new Intl.NumberFormat().format(max)
            document.getElementById("min-camera").innerHTML = min + "s";
            document.getElementById("max-camera").innerHTML = max + "s";

            gradientCfg = this.gradientConfig;
            let gradient = legendCtx.createLinearGradient(0, 0, 100, 1);
            gradient.addColorStop(0.05, "white")
            for (let key in gradientCfg) {
                gradient.addColorStop(key - 0.1, gradientCfg[key]);
            }

            legendCtx.fillStyle = gradient;
            legendCtx.fillRect(0, 0, 100, 10);

            document.getElementById(
                "gradient-camera"
            ).src = legendCanvas.toDataURL();
        },
    }
}
</script>

<style scoped lang="scss">
.heatmap-camera-container {
    display: flex;
    justify-content: center;
    position: relative;
    .wrap-img{
        position: relative;

        .gradientStyles {
            position: absolute;
            left: 50%;
            bottom: -40px;
            z-index: 9999;
            transform: translateX(-50%);
        }
    }
    

    .heatmap-container {
        position: relative;
    }

    .gradient-heatmap-camera {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .tooltip {
        position: absolute;
    }

    .camera-selection {
        margin-left: 24px;

        max-height: 480px;
        overflow-y: auto;

        .active {
            background-color: rgba(39, 110, 255, 0.1);
        }

        .camera-item:hover {
            background-color: rgba(39, 110, 255, 0.1);
            cursor: pointer;
        }

        .camera-item {
            border-radius: 8px;
            width: 258px;
            height: 44px;

            position: relative;

            margin-bottom: 1px;

            .tooltip-image {
                position: absolute;
                top: -478%;

                width: 208px;
                height: 208px;

                .close-button {
                    height: 32px;
                    width: 32px;
                    background: #434343;
                    line-height: 32px;
                    text-align: center;

                    position: absolute;
                    bottom: 84.5%;
                    left: 84.5%;
                }

                img {
                    width: 100%;
                    height: 100%;
                    position: relative;
                }
            }

            .camera-select-item {
                display: flex;
                align-items: center;
                width: 100%;
                height: 100%;
            }

            span:last-of-type {
                margin-left: auto;
            }

            .camera-name {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #000000;
            }
        }
    }
}

.loading:hover {
    cursor: wait !important;
}

.margin-left-10 {
    margin-left: 10px;
}

.margin-left-6 {
    margin-left: 6px;
}

.margin-right-12px {
    margin-right: 12px;
}

.skeleton-box {
    display: inline-block;
    position: relative;
    overflow: hidden;
    background-color: #DDDBDD;

    &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: translateX(-100%);
        background-image: linear-gradient(
                90deg,
                rgba(#fff, 0) 0,
                rgba(#fff, 0.2) 20%,
                rgba(#fff, 0.5) 60%,
                rgba(#fff, 0)
        );
        animation: shimmer 2s infinite;
        content: '';
    }

    @keyframes shimmer {
        100% {
            transform: translateX(100%);
        }
    }
}
</style>