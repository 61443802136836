<template>
    <div class="LastSection mt-40">
        <div class="RushHour">
            <div class="wraptitleRushhour">
                <span class="TitleCard">
                {{ $t("Report.RushHour").toUpperCase() }}
                </span>
                <md-switch class="md-success" v-if="dayToView > 14" v-model="isAverage">{{ $t("reportShopingMall.isAverage") }}</md-switch>
            </div>
            <HeatmapForReportShopingMall
                :listDateRange="listDateRangeForHeatmap"
                :rawData="heatmapData"
            >
            </HeatmapForReportShopingMall>
        </div>
        <div class="Tablekpi">
            <span class="TitleCard">
                {{ $t("dashboardShopingMall.titleForShopKPI").toUpperCase() }}
            </span>
            <KpiTableReport
                :rawDataForTable="tableKPIData"
                class=" tableKPIStyle" 
                >
            </KpiTableReport>
        </div>
    </div>
</template>

<script>
import {
    HeatmapForReportShopingMall,
    KpiTableReport,
} from "@/pages";
import EventBus from "@/EventBus";


import { Api } from "@/api/index";
const heatmapReportShoppingMall = Api.get("heatmapReportShoppingMall")
const storePerformanceReport = Api.get("storePerformanceReport")
import * as moment from "moment-timezone";

export default {
    components: {
        HeatmapForReportShopingMall, 
        KpiTableReport,
    },
    props: {
        dateRange: Object,
        compareType: String 
    },
    watch: {
        dateRange: {
            handler: function(val) {
                this.dateStart = val["dateStart"]
                this.dateEnd = val["dateEnd"]
                this.getDataFromAPIs()
            },
            deep: true
        },
        isAverage(val) {
            this.heatmapReportShoppingMallCallingAPI()
        },
        compareType(val) {
            this.getDataFromAPIs()
        },
    },
    mounted() {
        this.dateStart = this.dateRange.dateStart
        this.dateEnd = this.dateRange.dateEnd
        if (localStorage.lang == "en") {
            this.listDayInWeek = [
                "MO ",
                "TU ",
                "WE ",
                "TH ",
                "FR ",
                "SA ",
                "SU ",
            ]
        }
        this.getDataFromAPIs()
    },
    data() {
        return {
            isAverage: false,
            dateStart: 0,
            dateEnd: 0,
            dayToView: 7,
            listDateRangeForHeatmap: [],
            listDayInWeek: [
                "T2 ",
                "T3 ",
                "T4 ",
                "T5 ",
                "T6 ",
                "T7 ",
                "CN ",
            ],
            heatmapData: [],
            tableKPIData: [],
        }
    },
    methods: {
        heatmapReportShoppingMallCallingAPI() {
            let self = this
            heatmapReportShoppingMall.get(this.dateRange.dateStart, this.dateRange.dateEnd, this.isAverage).then(response => {
                self.heatmapData = response.data.data
            })
        },
        getDataFromAPIs() {
            let self = this
            this.listDateRange = []
            this.listDateRangeForHeatmap = []
            
            let dayToView = (this.dateEnd - this.dateStart) / 86400 + 1
            this.dayToView = dayToView
            if (dayToView > 15 && dayToView <= 105) {
                for (
                    let dateStart = parseInt(this.dateStart);
                    dateStart <= this.dateEnd;
                ) {
                    let endWeek = moment
                        .unix(dateStart)
                        .endOf("week")
                        .startOf("day")
                        .format("DD/MM");
                    let endWeekTimestamp = parseInt(
                        moment
                            .unix(dateStart)
                            .endOf("week")
                            .endOf("day")
                            .format("X")
                    );
                    let weekDay = ""
                    if (moment.unix(parseInt(this.dateEnd)).day() - 1 == -1) {
                        weekDay = this.listDayInWeek[
                            this.listDayInWeek.length - 1
                        ];
                    } else {
                        weekDay = this.listDayInWeek[
                            moment.unix(parseInt(this.dateEnd)).day() - 1
                        ];
                    }
                    let weekDayStart = ""
                    if (moment.unix(parseInt(dateStart)).day() - 1 == -1) {
                        weekDayStart = this.listDayInWeek[
                            this.listDayInWeek.length - 1
                        ];
                    } else {
                        weekDayStart = this.listDayInWeek[
                            moment.unix(parseInt(dateStart)).day() - 1
                        ];
                    }
                    if (endWeekTimestamp >= this.dateEnd) {
                        this.listDateRangeForHeatmap.push(
                            this.listDayInWeek[0] + " " +
                            moment
                                .unix(dateStart)
                                .startOf("day")
                                .format("DD/MM") +
                            " - " +
                            weekDay + " " +
                            moment
                                .unix(this.dateEnd)
                                .startOf("day")
                                .format("DD/MM")
                    );
                        break;
                    } else {
                        this.listDateRangeForHeatmap.push(
                            weekDayStart + " " +
                            moment
                                .unix(dateStart)
                                .startOf("day")
                                .format("DD/MM") +
                            " - " +
                            this.listDayInWeek[6] + " " +
                            endWeek
                        );
                        dateStart = endWeekTimestamp + 1;
                    }
                }
            } else if (dayToView > 105) {
                let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
                for (
                    let dateStart = parseInt(this.dateStart);
                    dateStart < this.dateEnd;

                ) {
                    let endMonth = moment
                        .unix(dateStart)
                        .endOf("month")
                        .startOf("day")
                        .format("DD/MM");
                    let endMonthTimestamp = parseInt(
                        moment
                            .unix(dateStart)
                            .endOf("month")
                            .endOf("day")
                            .format("X")
                    );

                    if (endMonthTimestamp >= this.dateEnd) {
                        let element = moment.unix(dateStart).startOf("day").format("DD/MM") + " - " + moment.unix(this.dateEnd).startOf("day").format("DD/MM")
                        if (this.listDateRangeForHeatmap.includes(element) == true) {
                            this.listDateRangeForHeatmap.push(element + " /" + characters.charAt(Math.floor(Math.random() * characters.length)))
                        } else {
                            this.listDateRangeForHeatmap.push(
                                element
                            );
                        }
                        break;
                    } else {
                        let element = moment.unix(dateStart).startOf("day").format("DD/MM") + " - " + endMonth
                        if (this.listDateRangeForHeatmap.includes(element) == true) {
                            this.listDateRangeForHeatmap.push(element + " /" + characters.charAt(Math.floor(Math.random() * characters.length)))
                        } else {
                            this.listDateRangeForHeatmap.push(
                                element
                            );
                        }
                        dateStart = endMonthTimestamp + 100;
                        
                    }
                }
            } else {
                for (
                    let dateStart = parseInt(this.dateStart);
                    dateStart <= this.dateEnd;
                    dateStart += 86400
                ) {
                    let weekDay = ""
                    if (moment.unix(dateStart).day() - 1 == -1) {
                        weekDay = this.listDayInWeek[
                            this.listDayInWeek.length - 1
                        ];
                    } else {
                        weekDay = this.listDayInWeek[
                            moment.unix(dateStart).day() - 1
                        ];
                    }
                    this.listDateRangeForHeatmap.push(
                        weekDay + " " +
                        moment
                            .unix(dateStart)
                            .endOf("day")
                            .format("DD/MM")
                    );
                }
            }
            for (let i = parseInt(this.dateStart); i <= parseInt(this.dateEnd); i = i + 86400) {

                let weekDay = ""
                if (moment.unix(i).day() - 1 == -1) {
                    weekDay = this.listDayInWeek[
                        this.listDayInWeek.length - 1
                    ];
                } else {
                    weekDay = this.listDayInWeek[
                        moment.unix(i).day() - 1
                    ];
                }
                
            }
            storePerformanceReport.get(this.dateStart, this.dateEnd, this.compareType).then(response => {
                self.tableKPIData = response.data.data
                EventBus.$emit("changeDataKPI", response.data.data);
            })

            this.heatmapReportShoppingMallCallingAPI()
        },
    }
}
</script>

<style lang="scss">
.LastSection {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 702px;
    row-gap: 0;
    column-gap: 24px;
    .RushHour {
        background: #ffffff;
        box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
        padding: 32px 24px 24px 24px;
        .wraptitleRushhour {
                display: flex;
                justify-content: space-between;
                align-items: center;
            .TitleCard {
                font-family: "Roboto";
                font-weight: 500;
                font-size: 20px;
                line-height: 24px;
                color: #1f1f1f;
            }
        }
        .wrapTreeMappRushhour {
            height: 476px;
            overflow: hidden;
            width: 100%
        }
    }
    .Tablekpi {
        background: #ffffff;
        box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
        padding: 32px 24px 24px 24px;
        position: relative;
        .TitleCard {
            font-family: "Roboto";
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            color: #1f1f1f;
        }
    }
}
</style>