<template>
    <div class="FootFall mt-40">
        <div class="ChartFootFall">
            <div class="WrapHeader">
                <span class="titleChart " style="margin-top:-18px">
                    {{ $t('dashboardShopingMall.titleForGateCard').toUpperCase() }}
                </span>
                <SelectMultiChoice
                    :ShowOption="ShowOptionLineChart"
                    :listZone="listGroup"
                    :HandleClickOption="handleClickOption"
                    v-click-outside="handleClickOutSideSelectZoneStatisticComeIn"
                    @select-multi-choice="handleMultiSelectZoneStatisticComeIn"
                    :title="$t('reportShopingMall.chooseGate')"
                />
            </div>
            <div ref="hiddenTrial2" class="HiddenTrial2"></div>
            <div class="wrapChartLine" v-if="hourLineChart == false">
                <LineChartForReportShoppingMallWeek
                    :maxNumber="maxNumber"
                    :rawData="dataGateTrending"
                    :year="year"
                    :listColorToUse="listColorToUse"
                    v-if="listDateRange.length < 22"
                />
                <LineChartForReportShoppingMallMonth
                    :maxNumber="maxNumber"
                    :rawData="dataGateTrending"
                    :year="year"
                    :listColorToUse="listColorToUse"
                    v-else-if="listDateRange.length >= 22 && listDateRange.length < 181"
                />
                <LineChartForReportShoppingMallYear
                    :maxNumber="maxNumber"
                    :rawData="dataGateTrending"
                    :year="year"
                    :listColorToUse="listColorToUse"
                    v-else-if="listDateRange.length >= 181"
                />
            </div>
            <div class="wrapChartLine" v-if="hourLineChart == true">
                <LineChartForReportShoppingMallDay
                    :maxNumber="maxNumber"
                    :rawData="dataGateTrending"
                    :listColorToUse="listColorToUse"
                />
            </div>

            <div class="wrapNote mt-8">
                <div class="note mr-8" v-for="(color, index) in listColorToUse" :key="color">
                    <div class="ColorNoteToday mr-8" v-bind:style="{ backgroundColor: color }"></div>
                    <span>{{ dataGateTrending[index].name }}</span>
                </div>
            </div>
        </div>
        <TreeMapForReportShoppingMall
            height="350"
            :dateRange="dateRange"
            :compareType="compareType"
            :isRetail="false"
        ></TreeMapForReportShoppingMall>
    </div>
</template>

<script>
import * as moment from 'moment-timezone';
import { Api } from '@/api/index';
import {
    LineChartForReportShoppingMallYear,
    LineChartForReportShoppingMallMonth,
    LineChartForReportShoppingMallWeek,
    LineChartForReportShoppingMallDay,
    SelectMultiChoice,
    TreeMapForReportShoppingMall,
} from '@/pages';

const getListGroupHaveGate = Api.get('getListGroupHaveGate');
const trendingReportShopingMall = Api.get('trendingReportShopingMall');

export default {
    components: {
        LineChartForReportShoppingMallYear,
        LineChartForReportShoppingMallMonth,
        LineChartForReportShoppingMallWeek,
        LineChartForReportShoppingMallDay,
        TreeMapForReportShoppingMall,
        SelectMultiChoice,
    },

    mounted() {
        if (localStorage.getItem('hiddenTrial2RefreshInReport') == true) {
            this.$refs['hiddenTrial2'].style.bottom = '28px';
        } else {
            this.$refs['hiddenTrial2'].style.bottom = '38px';
        }

        this.dateStart = this.dateRange.dateStart;
        this.dateEnd = this.dateRange.dateEnd;
        this.listDateRange = [];
        this.year = parseInt(moment.unix(this.dateEnd).format('YYYY'));
        for (let i = parseInt(this.dateRange.dateStart); i <= parseInt(this.dateRange.dateEnd); i = i + 86400) {
            this.listDateRange.push(
                moment
                    .unix(i)
                    .startOf('day')
                    .format('M/DD/YYYY')
            );
        }
        getListGroupHaveGate.get().then(response => {
            let listGroup = response.data.listGate;
            this.listGroup = listGroup;
            for (let i = 0; i < response.data.listGate.length; i++) {
                this.listGroupName.push(response.data.listGate[i].name);
            }
            this.getTrendingGate(true);
        });

        setTimeout(() => {
            this.timeoutSelectGate = false;
        }, 1000);
    },
    props: {
        dateRange: Object,
    },
    data() {
        return {
            ShowOptionLineChart: false,
            listGroup: [],
            groupIDForLineChart: [],
            listGroupIDForLineChart: [],
            dataGateTrending: [],
            dateStart: 0,
            dateEnd: 0,
            listColor: [
                '#004961',
                '#D81F41',
                '#68768A',
                '#00CC6A',
                '#004961',
                '#FFB900',
                '#0063B1',
                '#B146C2',
                '#0099BC',
            ],
            listColorToUse: [],
            year: '',
            listGroupName: [],
            listDateRange: [],
            hourLineChart: false,
            firstTime: true,
            maxNumber: 0,
            timeoutSelectGate: true,
        };
    },
    watch: {
        dateRange: {
            handler: function(val) {
                this.dateStart = val['dateStart'];
                this.dateEnd = val['dateEnd'];
                let dayToView = (this.dateRange.dateEnd - this.dateRange.dateStart) / 86400 + 1;
                if (dayToView == 1) {
                    this.hourLineChart = true;
                } else {
                    this.hourLineChart = false;
                }
                this.listDateRange = [];
                this.year = parseInt(moment.unix(this.dateEnd).format('YYYY'));
                for (let i = parseInt(this.dateRange.dateStart); i <= parseInt(this.dateRange.dateEnd); i = i + 86400) {
                    this.listDateRange.push(
                        moment
                            .unix(i)
                            .startOf('day')
                            .format('M/DD/YYYY')
                    );
                }
                this.getTrendingGate(this.firstTime);
            },
            deep: true,
        },
    },
    methods: {
        handleClickOption() {
            this.ShowOptionLineChart = !this.ShowOptionLineChart;
        },
        handleClickOutSideSelectZoneStatisticComeIn() {
            this.ShowOptionLineChart = false;
        },
        handleMultiSelectZoneStatisticComeIn(listSelectedZone) {
            if (this.timeoutSelectGate == false) {
                if (listSelectedZone.length == 0) {
                    this.groupIDForLineChart = '';
                    this.firstTime = true;
                    this.getTrendingGate(true);
                } else {
                    if (listSelectedZone.length == 1 || listSelectedZone.length == 0) {
                        this.groupIDForLineChart = listSelectedZone.toString();
                        this.listGroupIDForLineChart = this.groupIDForLineChart.split(',');
                    } else {
                        for (let index in listSelectedZone) {
                            if (this.listGroupIDForLineChart.indexOf(listSelectedZone[index]) == -1) {
                                this.listGroupIDForLineChart.push(listSelectedZone[index]);
                            }
                        }

                        for (let index in this.listGroupIDForLineChart) {
                            if (listSelectedZone.indexOf(this.listGroupIDForLineChart[index]) == -1) {
                                this.listGroupIDForLineChart.splice(index, 1);
                            }
                        }

                        this.groupIDForLineChart = this.listGroupIDForLineChart.toString();
                    }

                    this.getTrendingGate(false);
                    this.firstTime = false;
                }
            }
        },
        getTrendingGate(firstTime) {
            let self = this;
            trendingReportShopingMall.get(this.dateStart, this.dateEnd, this.groupIDForLineChart).then(response => {
                let rawData = response.data.data;
                if (firstTime == true && rawData.length > 2) {
                    let maxData = [];
                    for (let i = 0; i < 2; i++) {
                        let maxNumber = 0;
                        let maxIndex = 0;
                        for (let z = 0; z < rawData.length; z++) {
                            let sum = rawData[z].listTotalGate.reduce((partiulSum, a) => partiulSum + a, 0);
                            if (maxNumber < sum) {
                                maxNumber = sum;
                                maxIndex = z;
                            }
                        }
                        maxData.push(rawData[maxIndex]);
                        rawData.splice(maxIndex, 1);
                    }
                    rawData = maxData;
                }
                this.dataGateTrending = [];
                let dataGateTrending = [];
                this.listColorToUse = [];
                let maxNumber = 0;
                if (this.dateRange.dateStart - this.dateRange.dateEnd == 0) {
                    let hour = [
                        '1/11/2020',
                        '1/12/2020',
                        '1/13/2020',
                        '1/14/2020',
                        '1/15/2020',
                        '1/16/2020',
                        '1/17/2020',
                        '1/18/2020',
                        '1/19/2020',
                        '1/20/2020',
                        '1/21/2020',
                        '1/22/2020',
                        '1/23/2020',
                        '1/24/2020',
                        '1/25/2020',
                        '1/26/2020',
                        '1/27/2020',
                    ];

                    for (let i = 0; i < rawData.length; i++) {
                        var sum = Math.max(...rawData[i].listTotalGate);
                        if (maxNumber < sum) {
                            maxNumber = sum;
                        }
                        let pointData = [];
                        this.listColorToUse.push(self.listColor[self.listGroupName.indexOf(rawData[i].name)]);
                        for (let z = 0; z < rawData[i].listTotalGate.length; z++) {
                            pointData.push([hour[z], rawData[i].listTotalGate[z]]);
                        }
                        let dataOfOneGate = {
                            name: rawData[i].name,
                            defaultPoint: {
                                marker: {
                                    outline: {
                                        width: 1,
                                        color: 'white',
                                    },
                                    fill: self.listColor[self.listGroupName.indexOf(rawData[i].name)],
                                    type: 'circle',
                                    visible: true,
                                    size: 12,
                                },
                            },
                            line: {
                                color: self.listColor[self.listGroupName.indexOf(rawData[i].name)],
                                width: 2,
                            },
                            points: pointData,
                        };
                        dataGateTrending.push(dataOfOneGate);
                    }
                    if (maxNumber < 100) {
                        this.maxNumber = this.round5(Math.max(maxNumber));
                    } else if (maxNumber >= 100 && maxNumber < 500) {
                        this.maxNumber = this.round100(Math.max(maxNumber));
                    } else if (maxNumber >= 500) {
                        this.maxNumber = this.round500(Math.max(maxNumber));
                    }
                    this.dataGateTrending = dataGateTrending;
                } else {
                    for (let i = 0; i < rawData.length; i++) {
                        let pointData = [];
                        var sum = Math.max(...rawData[i].listTotalGate);
                        if (maxNumber < sum) {
                            maxNumber = sum;
                        }

                        this.listColorToUse.push(self.listColor[self.listGroupName.indexOf(rawData[i].name)]);
                        for (let z = 0; z < rawData[i].listTotalGate.length; z++) {
                            pointData.push([self.listDateRange[z], rawData[i].listTotalGate[z]]);
                        }
                        let dataOfOneGate = {
                            name: rawData[i].name,
                            defaultPoint: {
                                marker: {
                                    outline: {
                                        width: 1,
                                        color: 'white',
                                    },
                                    fill: self.listColor[self.listGroupName.indexOf(rawData[i].name)],
                                    type: 'circle',
                                    visible: true,
                                    size: 12,
                                },
                            },
                            line: {
                                color: self.listColor[self.listGroupName.indexOf(rawData[i].name)],
                                width: 2,
                            },
                            points: pointData,
                        };
                        dataGateTrending.push(dataOfOneGate);
                    }
                    if (maxNumber < 100) {
                        this.maxNumber = this.round5(Math.max(maxNumber));
                    } else if (maxNumber >= 100 && maxNumber < 500) {
                        this.maxNumber = this.round100(Math.max(maxNumber));
                    } else if (maxNumber >= 500) {
                        this.maxNumber = this.round500(Math.max(maxNumber));
                    }
                    this.dataGateTrending = dataGateTrending;
                }
            });
        },
        round5(x) {
            return Math.ceil(x / 5) * 5;
        },
        round100(x) {
            return Math.ceil(x / 100) * 100;
        },
        round500(x) {
            return Math.ceil(x / 500) * 500;
        },
    },
};
</script>

<style lang="scss">
.FootFall {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 564px;
    row-gap: 0;
    column-gap: 24px;
    .HiddenTrial2 {
        width: 5%;
        height: 9%;
        background-color: #ffffff;
        position: absolute;
        bottom: 28px;
        left: 18px;
        z-index: 2;
    }
    .ChartFootFall {
        background-color: #ffffff;
        box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
        height: 100%;
        padding: 24px;
        position: relative;

        .WrapHeader {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .titleChart {
                font-family: 'Roboto';
                font-weight: 500;
                font-size: 20px;
                line-height: 24px;
                color: #1f1f1f;
            }
            .Btn-ChooseGate {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 10px 16px;
                width: auto;
                height: 40px;
                border: 1px solid #276eff;
                box-sizing: border-box;
                border-radius: 8px;
                font-family: 'Roboto';
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: #276eff;
                position: relative;
                cursor: pointer;
                &:hover {
                    .WrapOption {
                        visibility: visible;
                    }
                }
                .WrapOption {
                    position: absolute;
                    background: #ffffff;
                    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
                    display: flex;
                    flex-direction: column;
                    min-width: 100%;
                    top: 40px;
                    z-index: 9;
                    visibility: hidden;
                    .Option {
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 20px;
                        padding: 16px;
                        color: #333333;
                        cursor: pointer;
                        &:hover {
                            background-color: #edf3ff;
                        }
                    }
                }
            }
        }
        .wrapChartLine {
            padding-top: 12px;
            height: 360px;
            overflow: hidden;
        }
        .wrapNote {
            flex-wrap: wrap;
            justify-content: center;
            display: flex;
            width: 100%;
            .note {
                display: flex;
                align-items: center;
                margin-top: 8px;
                .ColorNoteToday {
                    height: 16px;
                    width: 16px;
                    border-radius: 50%;
                    background-color: #d81f41;
                }
                .ColorNoteYesterday {
                    height: 16px;
                    width: 16px;
                    border-radius: 50%;
                    background-color: #004961;
                }
                .ColorNoteYesterday1 {
                    height: 16px;
                    width: 16px;
                    border-radius: 50%;
                    background-color: #68768a;
                }
                .ColorNoteYesterday2 {
                    height: 16px;
                    width: 16px;
                    border-radius: 50%;
                    background-color: #00cc6a;
                }
                .ColorNoteYesterday3 {
                    height: 16px;
                    width: 16px;
                    border-radius: 50%;
                    background-color: #ffb900;
                }
                span {
                    max-width: 108px;
                    min-width: 88px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }
        }
    }
    .Treemap {
        background-color: #ffffff;
        box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
        padding: 24px;

        .titleTree {
            font-family: 'Roboto';
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            color: #1f1f1f;
        }
    }
}
</style>
