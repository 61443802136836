<template>
    <div class="WrapOverView mt-40">
        <div class="FunnelChart">
            <funnel-chart
                :chartData="chartDataFunnel"
                :chartLeftMargin="chartLeftMargin"
            ></funnel-chart>
            <div class="HiddenTrial">
            </div>
        </div>
        <div class="ValueOverview">
            <CardRetailStore v-for="card in listDataCard" :key="card.title"
                :title="card.title"
                :total="card.total"
                :percentage="card.percentage"
                :icon="card.icon"
                :compareText="compareText"  
                fontSize="48px"  
            />
        </div>
    </div>
</template>

<script>
import FunnelChart from "../Charts/FunnelChart.vue"
import CardRetailStore from "../Card/CardRetailStore.vue"

import utils from "@/utils";

import { Api } from "@/api/index";
const overviewReportRetail = Api.get("overviewReportRetail")

export default {
    name: "OverviewSection",
    components: {
        FunnelChart, 
        CardRetailStore
    },
    props: {
        dateRange: {
            type: Object
        },
        compareType: {
            type: String,
            default: "normal"
        },
        compareText: {
            type: String
        },
    },
    watch: {
        dateRange: {
            handler: function(val) {
                this.dateStart = val["dateStart"]
                this.dateEnd = val["dateEnd"]
                this.getAPIOverviewData()
            },
            deep: true
        },
        compareType(val) {
            this.getAPIOverviewData()
        },
    },
    mounted() {
        this.dateStart = this.dateRange["dateStart"]
        this.dateEnd = this.dateRange["dateEnd"]
        this.getAPIOverviewData()
    },
    data() {
        return {
            chartLeftMargin: "-50",
            chartDataFunnel: [],
            listDataCard: [],
            dateStart: 0,
            dateEnd: 0,
        }
    },
    methods: {
        getAPIOverviewData() {
            overviewReportRetail.get("", this.dateStart, this.dateEnd, this.compareType).then(response => {
                this.listDataCard = []
                this.transformDataOverview(response.data.gate, this.$t("dashboard.Footfall"), response.data.gate_percentage)
                this.transformDataOverview(response.data.gate_rate, this.$t("dashboard.TurnInRate"), response.data.gate_rate_percentage)
                this.transformDataOverview(response.data.shop_rate, this.$t("dashboard.SaleConversionRate"), response.data.shop_rate_percentage)
                this.transformDataOverview(response.data.time_average, this.$t("dashboard.Dwelltime"), response.data.time_average_percentage)
                this.transformDataOverview(response.data.crowed_store_name, this.$t("Report.TheMostCrowdedStore"), response.data.crowed_store_percentage)
                this.transformDataOverview(response.data.crowded_day, this.$t("Report.TheMostCrowdedDay"), response.data.crowded_day_percentage)
                if (localStorage.lang == "en") {
                    if (response.data.way >= 10000) {
                        this.chartLeftMargin = "-30"
                    } else if (response.data.way >= 1000 && response.data.way < 10000) {
                        this.chartLeftMargin = "-30"
                    } else if (response.data.way >= 100 && response.data.way < 1000) {
                        this.chartLeftMargin = "-25"
                    } else if (response.data.way >= 10 && response.data.way < 100) {
                        this.chartLeftMargin = "-15"
                    } else if (response.data.way < 10) {
                        this.chartLeftMargin = "-13" 
                    }
                } else {
                     if (response.data.way >= 10000) {
                        this.chartLeftMargin = "-58"
                    } else if (response.data.way >= 1000 && response.data.way < 10000) {
                        this.chartLeftMargin = "-53"
                    } else if (response.data.way >= 100 && response.data.way < 1000) {
                        this.chartLeftMargin = "-48"
                    } else if (response.data.way >= 10 && response.data.way < 100) {
                        this.chartLeftMargin = "-42"
                    } else if (response.data.way < 10) {
                        this.chartLeftMargin = "-35" 
                    }
                }
                
                //Dùng cho bản tiếng việt
                
                this.chartDataFunnel = [
                    {
                        label: "",
                        value: "1460000"
                    },
                    {
                        label: this.$t("dashboard.Passby") + " (" + response.data.way.toLocaleString("en-EN") + ")",
                        value: "930000"
                    },
                    {
                        label: this.$t("dashboard.Footfall") + " (" + response.data.gate.toLocaleString("en-EN") + ")",
                        value: "540000"
                    },
                    {
                        label: this.$t("dashboard.Transactions") + " (" + response.data.shop.toLocaleString("en-EN") + ")",
                        value: "210000"
                    }
                ]
                // this.getTrendingData()
                // this.$swal.close()
            })
        },
        transformDataOverview(total, title, percentage) {
            let totalTransform = ""
            let percentageTransform = ""
            let iconTransform = ""

            if (title == this.$t("dashboard.Dwelltime")) {
                if (total == 100000001) {
                    total = 0
                }
                totalTransform = utils.transformTime(total)
            } else if (title == this.$t("Report.TheMostCrowdedStore") || title == this.$t("Report.TheMostCrowdedDay")) {
                totalTransform = total
            } else {
                if (total > 1000) {
                    totalTransform = total.toLocaleString('en-EN')
                } else {
                    totalTransform = total.toString()
                }
            }
            
            if (percentage > 1000) {
                percentageTransform = percentage.toLocaleString('en-EN')
            } else {
                percentageTransform = percentage.toString()
            }
            if (percentage > 0) {
                iconTransform = "arrow_upward"
            } else {
                iconTransform = "arrow_downward"
            }
            if (percentage == 100000001) {
                percentageTransform = 0
            }
            if (title == this.$t("dashboard.TurnInRate") || title == this.$t("dashboard.SaleConversionRate")) {
                totalTransform = totalTransform + "%"
            }

            this.listDataCard.push({
                "title": title,
                "total": totalTransform,
                "icon": iconTransform,
                "percentage": percentageTransform
            })
        },
    }
}
</script>

<style lang="scss">
.WrapOverView {
    display: grid;
    width: 100%;
    grid-template-columns: 516px 1fr;
    row-gap: 0;
    column-gap: 24px;
    height: 376px;
    .FunnelChart {
        height: 376px;
        position: relative;
        box-sizing: border-box;
        overflow: hidden;
        .wrapChartFunnel {
            position: absolute;
            top: -20px;
            left: -115px;
        }
        .HiddenTrial {
            width: 50px;
            height: 50px;
            background-color: #F8FBFF;
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 2;
        }
    }
    .ValueOverview {
        display: grid;
        height: 100%;
        grid-template-columns: repeat(3,1fr);
        row-gap: 56px;
        column-gap: 24px;
        padding: 0 24PX;
    }
}


</style>