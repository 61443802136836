var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"WrapModal"},[_c('div',{staticClass:"HeaderTitle"},[_vm._v(" "+_vm._s(_vm.$t("Employee.DepartmentList"))+" "),_c('span',{staticClass:"material-icons",on:{"click":function($event){return _vm.closeDepartmentModal()}}},[_vm._v("close")])]),_c('div',{staticClass:"wrapContent mt-16"},[_c('div',{staticClass:"wrapheader"},[_c('div',{staticClass:"nameSegment"},[_vm._v(_vm._s(_vm.$t("Employee.Department")))]),_c('div',{staticClass:"nameSegment"},[_vm._v(_vm._s(_vm.$t("Employee.Location")))]),_c('div',{staticClass:"nameSegment"},[_vm._v(_vm._s(_vm.$t("Employee.NumberOfEmployee")))]),_c('div',{staticClass:"nameSegment"},[_vm._v(_vm._s(_vm.$t("Customers.Action")))])]),_c('div',{staticClass:"WrapOptionCustom"},[_vm._l((_vm.listDepartment),function(department){return _c('div',{key:department.id,staticClass:"OptionCustom"},[(
                            department.departmentEditing ==
                                true
                        )?_c('div',{staticClass:"nameSegment"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.departmentValue),expression:"departmentValue"}],attrs:{"type":"text"},domProps:{"value":(_vm.departmentValue)},on:{"input":function($event){if($event.target.composing){ return; }_vm.departmentValue=$event.target.value}}})]):_vm._e(),(
                            department.departmentEditing ==
                                false
                        )?_c('div',{staticClass:"nameSegment"},[_vm._v(" "+_vm._s(department.department)+" ")]):_vm._e(),_c('div',{staticClass:"nameSegment"},[_vm._v(" "+_vm._s(department.listGroup[0])+" ")]),_c('div',{staticClass:"nameSegment"},[_vm._v(" "+_vm._s(department.total)+" ")]),_c('div',{staticClass:"EditIcon"},[(
                                department.departmentEditing ==
                                    true
                            )?_c('div',{staticClass:"EditOption",staticStyle:{"margin-right":"40px"}},[_c('span',{staticClass:"material-icons",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.updateNewDepartmentMethod(
                                        department.id
                                    )}}},[_vm._v("check")]),_c('span',{staticClass:"material-icons",staticStyle:{"cursor":"pointer","color":"#FF4343 !important"},on:{"click":function($event){return _vm.changeDepartment(
                                        department.id,
                                        !department.departmentEditing
                                    )}}},[_vm._v(" close ")])]):_vm._e(),(
                                department.departmentEditing ==
                                    false
                            )?_c('div',{staticClass:"EditIcon"},[_c('span',{staticClass:"material-icons ColorEdit",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.changeDepartment(
                                        department.id,
                                        !department.departmentEditing
                                    )}}},[_vm._v(" edit ")])]):_vm._e()])])}),(_vm.addingDepartment == true)?_c('div',{staticClass:"EditOption positionOption bg-White"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.departmentAddingValue),expression:"departmentAddingValue"}],staticClass:"EnterDepartment",attrs:{"placeholder":"Nhập phòng ban","type":"text","autofocus":""},domProps:{"value":(_vm.departmentAddingValue)},on:{"input":function($event){if($event.target.composing){ return; }_vm.departmentAddingValue=$event.target.value}}}),_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.handleClickOutsideGroupFilter),expression:"handleClickOutsideGroupFilter"}],staticClass:"selectCustom mr-16",class:{ ActiveButton: _vm.groupAvailable },on:{"click":function($event){return _vm.chooseGroupFilter()}}},[_c('span',{staticClass:"Texoverflow"},[_vm._v(" "+_vm._s(_vm.groupResult))]),_c('span',{staticClass:"material-icons"},[_vm._v("arrow_drop_down")]),_c('div',{ref:"wrapGroupFilterOptionDepartment",staticClass:"wrapOptionDepartment"},_vm._l((_vm.listGroup),function(group){return _c('div',{key:group.id},[_c('div',{ref:group.id,refInFor:true,staticClass:"Option",on:{"click":function($event){return _vm.changeGroupOption(
                                            group.id,
                                            group.name
                                        )}}},[_vm._v(" "+_vm._s(group.name)+" ")])])}),0)]),_c('span',{staticClass:"material-icons",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.addNewDepartmentMethod()}}},[_vm._v("check")]),_c('span',{staticClass:"material-icons",staticStyle:{"cursor":"pointer","color":"#FF4343 !important"},on:{"click":function($event){_vm.addingDepartment = !_vm.addingDepartment}}},[_vm._v("close")])]):_vm._e()],2),_c('div',{staticClass:"OptionEnd mt-16",on:{"click":function($event){_vm.addingDepartment = !_vm.addingDepartment}}},[_c('span',{staticClass:"mr-16 material-icons"},[_vm._v("add")]),_vm._v(" "+_vm._s(_vm.$t("Employee.AddDepartment"))+" ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }