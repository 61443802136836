<template>
    <div class="RushHour mt-40">
        <div class="wraptitleRushhour">
            <span class="TitleCard">
            {{ $t("Report.RushHour").toUpperCase() }}
            </span>
            <md-switch class="md-success" v-if="dayToView > 14" v-model="isAverage">{{ $t("reportShopingMall.isAverage") }}</md-switch>
        </div>
        <HeatmapForReportShopingMall
            reportType="retail" 
            :listDateRange="listDateRangeForHeatmap"
            :listDateRangeString="listDateRangeForHeatmapString"
            :rawData="heatmapData"
        />
        <div class="HiddenTrial"></div>
    </div>
</template>
<script>
import * as moment from "moment-timezone";
import { Api } from "@/api/index";

moment.tz.setDefault("Asia/Ho_Chi_Minh");
moment.locale("vi");
import HeatmapForReportShopingMall from "../Charts/HeatmapForReportShopingMall.vue"

const rushHourRetailAPI = Api.get("rushHourRetail")

export default {
    components: {
        HeatmapForReportShopingMall
    },
    props: {
        dateRange: {
            type: Object
        },
        choosenGroupID: {
            type: String
        }
    },
    mounted() {
        this.dateStart = this.dateRange.dateStart
        this.dateEnd = this.dateRange.dateEnd
        this.getDataFromAPIs()
        this.heatmapReportRetailCallingAPI()
    },
    watch: {
        isAverage() {
            this.heatmapReportRetailCallingAPI()
        },
        dateRange: {
            handler: function(val) {
                this.dateStart = val["dateStart"]
                this.dateEnd = val["dateEnd"]
                this.getDataFromAPIs()
                this.heatmapReportRetailCallingAPI()
            },
            deep: true
        },
        choosenGroupID() {
            this.heatmapReportRetailCallingAPI()
        },
    },
    data() {
        let self = this
        return {
            dayToView: 7,
            dateStart: 0,
            dateEnd: 0,
            listDateRangeForHeatmapString: [
                moment()
                    .subtract(7, "day")
                    .startOf("day")
                    .format("DD/MM/YYYY"),
                moment()
                    .subtract(6, "day")
                    .startOf("day")
                    .format("DD/MM/YYYY"),
                moment()
                    .subtract(5, "day")
                    .startOf("day")
                    .format("DD/MM/YYYY"),
                moment()
                    .subtract(4, "day")
                    .startOf("day")
                    .format("DD/MM/YYYY"),
                moment()
                    .subtract(3, "day")
                    .startOf("day")
                    .format("DD/MM/YYYY"),
                moment()
                    .subtract(2, "day")
                    .startOf("day")
                    .format("DD/MM/YYYY"),
                moment()
                    .subtract(1, "day")
                    .startOf("day")
                    .format("DD/MM/YYYY")],
            listDateRangeForHeatmap: [],
            heatmapData: [],
            isAverage: false,
        }
    },
    methods: {
        heatmapReportRetailCallingAPI() {
            let self = this
            rushHourRetailAPI.get(this.choosenGroupID, this.dateRange.dateStart, this.dateRange.dateEnd, this.isAverage).then(response => {
                self.heatmapData = response.data.data
            })
        },
        getDataFromAPIs() {
            let listDayInWeek = ["T2 ", "T3 ", "T4 ", "T5 ", "T6 ", "T7 ", "CN "]
            if (localStorage.lang == "en") {
                listDayInWeek = [
                    "Mo ",
                    "Tu ",
                    "WE ",
                    "TH ",
                    "FR ",
                    "SA ",
                    "SU ",
                ]
            }
            this.listDateRangeForHeatmap = []
            this.listDateRangeForHeatmapString = []
            this.year = parseInt(moment.unix(this.dateEnd).format("YYYY"))
            let dayToView = (this.dateEnd - this.dateStart) / 86400 + 1
            this.dayToView = dayToView
            if (dayToView > 15 && dayToView <= 105) {
                for (
                    let dateStart = parseInt(this.dateStart);
                    dateStart <= this.dateEnd;
                ) {
                    let endWeek = moment
                        .unix(dateStart)
                        .endOf("week")
                        .startOf("day")
                        .format("DD/MM");
                    let endWeekString = moment
                        .unix(dateStart)
                        .endOf("week")
                        .startOf("day")
                        .format("DD/MM/YYYY");
                    let endWeekTimestamp = parseInt(
                        moment
                            .unix(dateStart)
                            .endOf("week")
                            .endOf("day")
                            .format("X")
                    );
                    let weekDay = ""
                    if (moment.unix(parseInt(this.dateEnd)).day() - 1 == -1) {
                        weekDay = listDayInWeek[
                            listDayInWeek.length - 1
                        ];
                    } else {
                        weekDay = listDayInWeek[
                            moment.unix(parseInt(this.dateEnd)).day() - 1
                        ];
                    }
                    let weekDayStart = ""
                    if (moment.unix(parseInt(dateStart)).day() - 1 == -1) {
                        weekDayStart = listDayInWeek[
                            listDayInWeek.length - 1
                        ];
                    } else {
                        weekDayStart = listDayInWeek[
                            moment.unix(parseInt(dateStart)).day() - 1
                        ];
                    }
                    if (endWeekTimestamp >= this.dateEnd) {
                        this.listDateRangeForHeatmap.push(
                            listDayInWeek[0] + " " +
                            moment
                                .unix(dateStart)
                                .startOf("day")
                                .format("DD/MM") +
                            " - " +
                            weekDay + " " +
                            moment
                                .unix(this.dateEnd)
                                .startOf("day")
                                .format("DD/MM")
                        );
                        this.listDateRangeForHeatmapString.push(
                            moment
                                .unix(dateStart)
                                .startOf("day")
                                .format("DD/MM/YYYY") +
                            " - " +
                            moment
                                .unix(this.dateEnd)
                                .startOf("day")
                                .format("DD/MM/YYYY")
                        );
                        break;
                    } else {
                        this.listDateRangeForHeatmap.push(
                            weekDayStart + " " +
                            moment
                                .unix(dateStart)
                                .startOf("day")
                                .format("DD/MM") +
                            " - " +
                            listDayInWeek[6] + " " +
                            endWeek
                        );
                        this.listDateRangeForHeatmapString.push(
                            moment
                                .unix(dateStart)
                                .startOf("day")
                                .format("DD/MM/YYYY") +
                            " - " +
                            endWeekString
                        );
                        dateStart = endWeekTimestamp + 1;
                    }
                }
            } else if (dayToView > 105) {
                
                for (
                    let dateStart = parseInt(this.dateStart);
                    dateStart < this.dateEnd+20000; 

                ) {
                    let endMonth = moment
                        .unix(dateStart)
                        .endOf("month")
                        .startOf("day")
                        .format("DD/MM");
                    let endMonthString = moment
                        .unix(dateStart)
                        .endOf("month")
                        .startOf("day")
                        .format("DD/MM/YYYY");
                    let endMonthTimestamp = parseInt(
                        moment
                            .unix(dateStart)
                            .endOf("month")
                            .endOf("day")
                            .format("X")
                    );
                    let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
                    if (endMonthTimestamp >= this.dateEnd) {
                        let element = moment.unix(dateStart).startOf("day").format("DD/MM") + " - " + moment.unix(this.dateEnd).startOf("day").format("DD/MM")
                        if (this.listDateRangeForHeatmap.includes(element) == true) {
                            this.listDateRangeForHeatmap.push(element + " /" + characters.charAt(Math.floor(Math.random() * characters.length)) + characters.charAt(Math.floor(Math.random() * characters.length)))
                        } else {
                            this.listDateRangeForHeatmap.push(
                                element
                            );
                        }
                        this.listDateRangeForHeatmapString.push(
                            moment
                                .unix(dateStart)
                                .startOf("day")
                                .format("DD/MM/YYYY") +
                                " - " +
                                moment
                                    .unix(this.dateEnd)
                                    .startOf("day")
                                    .format("DD/MM/YYYY")
                        );
                        break;
                    } else {
                        let element = moment.unix(dateStart).startOf("day").format("DD/MM") + " - " + endMonth
                        if (this.listDateRangeForHeatmap.includes(element) == true) {
                            this.listDateRangeForHeatmap.push(element + " /" + characters.charAt(Math.floor(Math.random() * characters.length)))
                        } else {
                            this.listDateRangeForHeatmap.push(
                                element
                            );
                        }
                        this.listDateRangeForHeatmapString.push(
                            moment
                                .unix(dateStart)
                                .startOf("day")
                                .format("DD/MM/YYYY") +
                                " - " +
                                endMonthString
                        );
                        dateStart = endMonthTimestamp + 100;
                    }
                }
            } else {
                for (
                    let dateStart = parseInt(this.dateStart);
                    dateStart <= this.dateEnd;
                    dateStart += 86400
                ) {
                    let weekDay = ""
                    if (moment.unix(dateStart).day() - 1 == -1) {
                        weekDay = listDayInWeek[
                            listDayInWeek.length - 1
                        ];
                    } else {
                        weekDay = listDayInWeek[
                            moment.unix(dateStart).day() - 1
                        ];
                    }
                    this.listDateRangeForHeatmap.push(
                        weekDay + " " +
                        moment
                            .unix(dateStart)
                            .endOf("day")
                            .format("DD/MM")
                    );
                    this.listDateRangeForHeatmapString.push(
                        moment
                            .unix(dateStart)
                            .endOf("day")
                            .format("DD/MM/YYYY")
                    );
                }
            }
            
        },
    }
}
</script>
<style lang="scss">
.RushHour {
    background: #FFFFFF;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
    height: 582px;
    padding: 24px;
    position: relative;
    .wraptitleRushhour {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .TitleCard {
            font-family: "Roboto";
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            color: #1f1f1f;
        }
    }
    .HiddenTrial {
        width: 150px;
        height: 50px;
        background-color: #FFFFFF;
        position: absolute;
        bottom: 13px;
        left: 26px;
        z-index: 2;
    }
}
</style>