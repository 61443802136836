<template>
<div>
    <div class="WrapModal">
        <div class="HeaderTitle">
            {{ $t("Employee.DepartmentList") }}
            <span
                class="material-icons"
                @click="closeDepartmentModal()"
                >close</span
            >
        </div>
        <div class="wrapContent mt-16">
            <div class="wrapheader">
                <div class="nameSegment">{{ $t("Employee.Department") }}</div>
                <div class="nameSegment">{{ $t("Employee.Location") }}</div>
                <div class="nameSegment">{{ $t("Employee.NumberOfEmployee") }}</div>
                <div class="nameSegment">{{ $t("Customers.Action") }}</div>
            </div>
            <div class="WrapOptionCustom">
                <div
                    v-for="department in listDepartment"
                    :key="department.id"
                    class="OptionCustom"
                >
                    <div
                        class="nameSegment"
                        v-if="
                            department.departmentEditing ==
                                true
                        "
                    >
                        <input
                            type="text"
                            v-model="departmentValue"
                        />
                        
                    </div>
                    <div
                        class="nameSegment"
                        v-if="
                            department.departmentEditing ==
                                false
                        "
                    >
                        {{ department.department }}
                    </div>
                    <div
                        class="nameSegment"
                    >
                        {{ department.listGroup[0] }}
                    </div>
                    <div
                        class="nameSegment"
                    >
                        {{ department.total }}
                    </div>
                    <div class="EditIcon">
                        <div
                            class="EditOption"
                            style="margin-right:40px"
                            v-if="
                                department.departmentEditing ==
                                    true
                            "
                        >
                            <span
                                class="material-icons"
                                @click="
                                    updateNewDepartmentMethod(
                                        department.id
                                    )
                                "
                                style="cursor: pointer;"
                                >check</span
                            >
                            <span
                                class="material-icons"
                                @click="
                                    changeDepartment(
                                        department.id,
                                        !department.departmentEditing
                                    )
                                "
                                style="cursor: pointer; color: #FF4343 !important;"
                            >
                                close
                            </span>
                        </div>
                        <div
                            class="EditIcon"
                            v-if="
                                department.departmentEditing ==
                                    false
                            "
                        >
                            <span
                                class="material-icons ColorEdit"
                                @click="
                                    changeDepartment(
                                        department.id,
                                        !department.departmentEditing
                                    )
                                "
                                style="cursor: pointer;"
                            >
                                edit
                            </span>
                        </div>
                    </div>
                </div>
                <div
                    class="EditOption positionOption bg-White"
                    v-if="addingDepartment == true"
                >
                    <input
                        placeholder="Nhập phòng ban"
                        type="text"
                        v-model="departmentAddingValue"
                        class="EnterDepartment"
                        autofocus
                    />
                    <div
                        class="selectCustom mr-16"
                        @click="chooseGroupFilter()"
                        v-click-outside="handleClickOutsideGroupFilter"
                        v-bind:class="{ ActiveButton: groupAvailable }"
                    >
                        <span class="Texoverflow">
                        {{ groupResult }}</span>
                        <span class="material-icons">arrow_drop_down</span>
                        <div ref="wrapGroupFilterOptionDepartment" class="wrapOptionDepartment">
                            <div
                                v-for="group in listGroup"
                                :key="group.id"
                            >
                                <div
                                    :ref="group.id"
                                    class="Option"
                                    @click="
                                        changeGroupOption(
                                            group.id,
                                            group.name
                                        )
                                    "
                                >
                                    {{ group.name }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <span
                        class="material-icons"
                        style="cursor: pointer"
                        @click="addNewDepartmentMethod()"
                        >check</span
                    >
                    <span
                        class="material-icons"
                        style="cursor: pointer; color: #FF4343 !important;"
                        @click="
                            addingDepartment = !addingDepartment
                        "
                        >close</span
                    >
                </div>
            </div>
            <div
                class="OptionEnd mt-16"
                @click="addingDepartment = !addingDepartment"
            >
                <span class="mr-16 material-icons">add</span>
                {{ $t("Employee.AddDepartment") }}
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { Api } from "@/api/index"

const getListGroup = Api.get("getListGroupV2");
const getListDepartment = Api.get("getListDepartmentV3");
const updateDepartment = Api.get("updateDepartment");
const addDepartmentV3 = Api.get("addDepartmentV3");

export default {
    mounted() {
        this.getListDepartment()
        getListGroup.get().then(response => {
            this.listGroup = response.data["listResponse"];
        });
    },
    data() {
        return {
            listDepartment: [],
            departmentValue: "",
            addingDepartment: false,
            departmentAddingValue: "",
            groupOfUpdatingDepartment: "",
            departmentUpdatingID: "",
            groupResult: "",
            groupSelected: "",
        }
    },
    methods: {
        getListDepartment() {
            let self = this
            getListDepartment.get().then(response => {
                let listDepartment =
                    response.data["listResponse"];

                for (let i = 0; i < listDepartment.length; i++) {
                    listDepartment[i].departmentEditing = false;
                    self.departmentValue = "";
                }
                this.listDepartment = response.data.listResponse;
            })
            .catch(error => {
                self.listDepartment = [];
                this.$notify({
                    timeout: 2500,
                    message: self.$t("EmployeeResponse."+ error.response.data.message.replace(".","")),
                    icon: "add_alert",
                    horizontalAlign: "right",
                    verticalAlign: "top",
                    type: "primary"
                });
            });
        },
        closeDepartmentModal() {
            this.$emit("closeDepartmentModal")
        },
        updateNewDepartmentMethod() {
            let self = this
            this.$swal({
                title: this.$t("CustomerResponse.CM99999"),
                allowOutsideClick: true
            });
            this.$swal.showLoading();
            let data = {
                "name": this.departmentValue,
                "listGroup": this.groupOfUpdatingDepartment
            }
            updateDepartment.put(this.departmentUpdatingID, data).then(response => {
                self.$swal.close()
                self.getListDepartment()
                self.$swal(
                    self.$t(
                        "EmployeeResponse." +
                            response.data.message.replace(".", "")
                    ),
                    "",
                    "success"
                );
            })
            .catch(error => {
                this.$swal.close();
                this.$swal(
                    self.$t(
                        "EmployeeResponse." +
                            error.response.data.message.replace(".", "")
                    ),
                    "",
                    "warning"
                );
            });
        },
        changeDepartment(departmentID, input) {
            
            if (input === false) {
                for (let i = 0; i < this.listDepartment.length; i++) {
                    if (this.listDepartment[i].id === departmentID) {
                        this.listDepartment[i].departmentEditing = false;
                        this.departmentValue = "";
                        this.groupOfUpdatingDepartment = []
                        this.departmentUpdatingID = ""
                    }
                }
            } else {
                for (let i = 0; i < this.listDepartment.length; i++) {
                    if (this.listDepartment[i].id === departmentID) {
                        this.listDepartment[i].departmentEditing = true;
                        this.departmentValue = this.listDepartment[i].department;
                        this.groupOfUpdatingDepartment = this.listDepartment[i].listGroup
                        this.departmentUpdatingID = this.listDepartment[i].id
                    } else {
                        this.listDepartment[i].departmentEditing = false;
                    }
                }
            }
        },
        addNewDepartmentMethod() {
            let data = {}
            if (this.groupSelected === "") {
                data = {...{
                        "name": this.departmentAddingValue,
                        "listGroup": []
                    }}
            } else {
                data = {...{
                        "name": this.departmentAddingValue,
                        "listGroup": [this.groupSelected]
                    }
                }                 
            }
            
            addDepartmentV3
                .post(data)
                .then((_) => {
                    this.getListDepartment()
                    this.addingDepartment = false
                    this.$swal(this.$t("Employee.AddDepartmentSuccess"), "", "success");
                })
                .catch((error) => {
                    this.$swal.close();
                    this.$swal(this.$t("EmployeeResponse." + error.response.data.message.replace(".","")), "", "warning");
                });
            
        },
        changeEditDepartmentMode(departmentID, input) {
            if (input === false) {
                for (let i = 0; i < this.listDepartment.length; i++) {
                    if (this.listDepartment[i].id === departmentID) {
                        this.listDepartment[i].departmentEditing = false;
                        this.departmentValue = "";
                    }
                }
            } else {
                for (let i = 0; i < this.listDepartment.length; i++) {
                    if (this.listDepartment[i].id === departmentID) {
                    } else {
                        this.listDepartment[i].departmentEditing = false;
                    }
                }
            }
        },
        chooseGroupFilter() {
            this.$refs["wrapGroupFilterOptionDepartment"].style.position =
                "absolute";
            this.$refs["wrapGroupFilterOptionDepartment"].style.display = "block";
            this.$refs["wrapGroupFilterOptionDepartment"].style.visibility =
                "visible";
            this.groupAvailable = true;
        },
        handleClickOutsideGroupFilter() {
            this.$refs["wrapGroupFilterOptionDepartment"].style.display = "none";
            this.$refs["wrapGroupFilterOptionDepartment"].style.visibility =
                "hidden";
            this.groupAvailable = false;
        },
        changeGroupOption(id, name) {
            let self = this
            this.page = 1
            this.groupSelected = name;
            this.groupResult = name;
            setTimeout(() => {
                self.handleClickOutsideGroupFilter()
            }, 50);
        }
    }

}
</script>
<style lang="scss" scope>
.wrapOption {
    position: absolute;
    display: none;
    padding: 8px;
    z-index: 9999;
    width: 100% !important;
    background: #ffffff;
    box-shadow: 0px 0px 1px rgba(10, 31, 68, 0.08),
        0px 3px 4px rgba(10, 31, 68, 0.1);
    border-radius: 10px;
    top: 40px;
    left: -2px;
    max-height: 312px;
    overflow: auto;
    .Option {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 8px 16px;
        width: 100%;
        height: auto;
        font-family: "Roboto";
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #333333;
        cursor: pointer;
            &:hover {
                background: #EDF3FF;
            }
    }
    .OptionActive {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 8px 16px;
        width: 100%;
        height: auto;
        font-family: "Roboto";
        background-color: #edf3ff;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #333333;
        cursor: pointer;
    }
    
}
.selectCustom {
    display: flex;
    align-items: center;
    padding: 10px 16px;
    margin-left: 10px;
    width: 212px;
    height: 40px;
    box-sizing: border-box;
    border-radius: 8px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #5a6170;
    border: 1px solid #8c8c8c;
    justify-content: space-between;
    position: relative;
    cursor: pointer;
    
    .wrapOption {
        position: absolute;
        display: none;
        padding: 8px;
        z-index: 9999;
        width: 100% !important;
        background: #ffffff;
        box-shadow: 0px 0px 1px rgba(10, 31, 68, 0.08),
            0px 3px 4px rgba(10, 31, 68, 0.1);
        border-radius: 10px;
        top: 40px;
        left: -2px;
        max-height: 312px;
        overflow: auto;
        .Option {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 8px 16px;
            width: 100%;
            height: auto;
            font-family: "Roboto";
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #333333;
            cursor: pointer;
                &:hover {
                    background: #EDF3FF;
                }
        }
        .OptionActive {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 8px 16px;
            width: 100%;
            height: auto;
            font-family: "Roboto";
            background-color: #edf3ff;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #333333;
            cursor: pointer;
        }
    }
    .wrapOptionDepartment {
        position: absolute;
        display: none;
        padding: 8px;
        z-index: 9999;
        width: 100% !important;
        background: #ffffff;
        box-shadow: 0px 0px 1px rgba(10, 31, 68, 0.08),
            0px 3px 4px rgba(10, 31, 68, 0.1);
        border-radius: 10px;
        bottom: 44px;
        left: -2px;
        max-height: 312px;
        overflow: auto;
        .Option {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 8px 16px;
            width: 100%;
            height: auto;
            font-family: "Roboto";
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #333333;
            cursor: pointer;
                &:hover {
                    background: #EDF3FF;
                }
        }
        .OptionActive {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 8px 16px;
            width: 100%;
            height: auto;
            font-family: "Roboto";
            background-color: #edf3ff;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #333333;
            cursor: pointer;
        }
    }
    // &:hover {
    //     .wrapOption {
    //         position: absolute;
    //         display: block;
    //     }
    // }
    .Texoverflow {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: "...";
    }
}
.WrapModal {
    padding: 24px;
    .HeaderTitle {
        font-family: "Roboto";
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: hsl(0, 0%, 12%);
        display: flex;
        align-items: center;
        justify-content: space-between;
        .material-icons {
            cursor: pointer;
        }
    }
    .wrapContent {
        .wrapheader {
            padding: 16px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: #064B6C;
            color: #fff;
            .nameSegment {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                width: 104px;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align:right;
            }
        }
        .btn-search {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 12px 16px;
            overflow: hidden;
            width: 204px;
            height: 40px;
            border: 1px solid #8c8c8c;
            box-sizing: border-box;
            border-radius: 8px;
            color: #bfbfbf;
            cursor: pointer;
            
            .material-icons {
                color: #8c8c8c;
            }
            input {
                font-family: "Roboto";
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: #1f1f1f;
                width: 100%;
                height: 38px;
                border: 0px solid #fff;
                &::placeholder {
                    color: #BFBFBF;
                }
            }
        }
        .WrapOptionCustom {
            display: flex;
            align-items: center;
            flex-direction: column;
            height: 312px;
            overflow: auto;
            z-index: 9999;
            .nameSegment {
                width:104px;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                input {
                    border: 1px solid #8c8c8c;
                    box-sizing: border-box;
                    border-radius: 8px;
                    margin-left: 38%;
                    margin-right: 8%;
                    width: 148px;
                    padding:12px 16px;
                }
                .material-icons {
                    color: #107c10;
                    margin-left: 16px;
                }
            }
            .OptionCustom {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                padding: 16px;
                border-top: 1px solid #d9d9d9;
                .EditIcon {
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    width: 13%;
                }

                .TypeCustomer {
                    font-family: "Roboto";
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    color: #333333;
                }
            }
            .OptionCustom:first-child {
                border-top: none;
            }
            .EditOption {
                display: flex;
                align-items: center;
                width: 80%;
                font-family: "Roboto";
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                .EnterDepartment {
                    width: 157px;
                    height: 40px;
                    border: 1px solid #4F7DFF;
                    box-sizing: border-box;
                    border-radius: 8px;
                    padding: 8px 16px;
                }
                .material-icons {
                    color: #107c10;
                    margin-left: 16px;
                }
            }
            .positionOption {
                position: absolute;
                bottom: 64px;
                left: 24px;
            }
            .bg-White {
                background-color: #fff;
            }
        }
        .OptionEnd {
            display: flex;
            align-items: center;
            width: 100%;
            color: #276eff;
            font-family: "Roboto";
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            cursor: pointer;
        }
    }
}
</style>