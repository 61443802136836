<template>
    <div>
        <div class="detail-report-main-header">
            <div class="wrapHeaderHeatmap">
                <span class="titleHeatmap">
                {{ $t("menu.Heatmap").toUpperCase()}} ({{selectListLayoutName}})
                </span>
                <div class="custom-button">
                    {{selectListLayoutName}}
                    <span class="material-icons"> arrow_drop_down </span>
                    <div class="wrapOption">
                        <div
                            class="Option"
                            v-for="item in listGroup"
                            :key="item.id"
                            @click="changeLayout(item.id, item.name)"
                        >
                            {{ item.name }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="heat-map-dashboard" style="margin-top:15px" v-if="listLayout.length > 0">
                <HeatmapLayout :heatmapData="heatmapData" :isDashboard="isDashboard">
                </HeatmapLayout>
                
            </div>
            <span class="heat-map-dashboard-text" v-else>{{ $t('Heatmap.HeatmapLayoutNotFound') }}</span>
        </div>
    </div>
</template>

<script>
import moment from "moment-timezone";
import "moment/locale/vi";
import {HeatmapLayout} from "@/pages";
import {Api} from "@/api/index";

const getReportHeatmapLayout = Api.get("heatmapLayout");
const getListLayout = Api.get("listLayout")
const getListGroup = Api.get("getListGroupV2");

moment.tz.setDefault("Asia/Ho_Chi_Minh");
moment.locale("vi");
export default {
    components: {
        HeatmapLayout,
    },
    mounted() {
        this.lang = localStorage.lang == "vn" ? "vi" : "en"
        var self = this
        getListGroup.get().then((response) => {
            var listGroup = response.data.listResponse;
            this.listGroup = listGroup;
            for (let i = 0; i < this.listGroup.length; i++) {
                getListLayout.get(this.listGroup[i].id).then(responseLayout => {
                    if (responseLayout.data.listLayoutResponse != null) {
                        for (let z = 0; z < responseLayout.data.listLayoutResponse.length; z++) {
                            if (self.listLayout.indexOf(responseLayout.data.listLayoutResponse[z]) == -1) {
                                var layout = responseLayout.data.listLayoutResponse[z]
                                layout.groupID = self.listGroup[i].id
                                self.listLayout.push(layout)
                            }
                        }
                        if (self.listLayout.length > 0) {
                            self.selectListLayout = self.listLayout[0].id
                            self.selectListLayoutName = self.listLayout[0].name
                        }
                    }
                })

            }
            
        });
    },
    data() {
        return {
            dateStart: moment().startOf("day").format("X"),
            dateEnd: moment().startOf("day").format("X"),
            listLayout: [],
            selectListLayout: "",
            selectListLayoutName: "",
            lang: "vn",
            heatmapData: [],
            isDashboard: true
        }
    },
    watch: {
        selectListLayout(val) {
            for (let i = 0; i < this.listLayout.length; i++) {
                if (val == this.listLayout[i].id) {
                    this.fetchDataHeatmapLayout(this.listLayout[i].groupID, this.listLayout[i].id, this.dateStart, this.dateEnd)
                }
            }
        },
        selectHeatmapType(value) {
            this.$emit("update-heatmap-type", value)
        },
    },
    methods: {
        changeLayout(id, name) {
            this.selectListLayout = id;
            this.groupName = name;
        },
        fetchDataHeatmapLayout(groupID, layoutID, dateStart, dateEnd) {
            getReportHeatmapLayout
                .get(dateStart, dateEnd, groupID, layoutID)
                .then((response) => {
                    this.heatmapData = response.data
                });
        }
    }
}
</script>

<style lang="scss" scoped>
.heat-map-dashboard {
    width: 100%;
    height: 100%;
    // padding: 3%;
    display: flex;
    justify-content: center;

    .VueCarousel {
        width: 84%;

        .VueCarousel-navigation button {
            font-size: 30px;
        }

        .heatmap-canvas {
            border-radius: 15px;
        }
    }
    .heat-map-dashboard-text {
        text-align: left;
        align-content: left;
        justify-content: left;
    }
    .heat-map-can {
        width: 100%;
        height: 100%;
    }
}

.detail-report-main-container {
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 0 20px 20px 20px;

    .detail-report-title {
        font-size: 18px;
        text-transform: uppercase;
        font-weight: bold;
    }

    .detail-select-container {
        display: flex;

        .select-area {
            width: 20%;
            margin-left: auto;
            margin-top: 0;
        }
    }

    .note-conversion-rate {
        padding-left: 25px;

        h4 {
            margin: 0;
            color: #ff6459;
            font-weight: bold;
        }

        ul > li {
            font-size: 15px;
            color: #7f7f7f;
            margin: 20px 0 0 40px;
        }
    }

    .detail-report-container {
        padding: 30px;
        background: #fff;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .stacked-bar-chart-container {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            row-gap: 50px;
        }

        .emotion-container {
            display: flex;

            .emotion-container-left {
                width: 75%;
                height: auto;
                padding-right: 40px;
            }

            .emotion-container-right {
                width: 25%;
            }
        }
    }

    .detail-report-sub-container {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(2, 1fr);
        row-gap: 50px;
        column-gap: 50px;
    }
}

@media only screen and (max-width: 1280px) {
    .detail-report-main-container {
        gap: 30px;

        .note-conversion-rate {
            ul > li {
                margin: 13px 0 0 30px;
            }
        }

        .detail-report-sub-container {
            row-gap: 40px;
            column-gap: 40px;
        }
    }
}
</style>