<template>
    <div class="WrapChartTable mt-40">
        <div>
            <TreeMapForReportShoppingMall class="mt-2px"
                height=400 
                :dateRange="dateRange"
                :compareType="compareType"
                :isRetail="true"/>
        </div>
        <div class="HeatmapTable">
            <KPIRetailStore 
                isReport="true"
                :dateRangeString="dateRangeString"/> 
        </div>
    </div>
</template>

<script>
import {
    // KPIRetailStore,
    TreeMapForReportShoppingMall,
} from "@/pages"

import KPIRetailStore from "../Tables/KPIRetailStore.vue"
export default {
    name: "OverviewStoreSection",
    components: {
        KPIRetailStore,
        TreeMapForReportShoppingMall
    },

    props: {
        dateRange: {
            type: Object
        },
        compareType: {
            type: String,
            default: "normal"
        },
        dateRangeString: {
            type: String,
        }
    }
}
</script>

<style lang="scss">
.WrapChartTable {
    display: grid;
    height: 532px;
    width: 100%;
    grid-template-columns: 516px 1fr;
    row-gap: 0;
    column-gap: 24px;
    .HeatmapTable {
        background: #FFFFFF;
        box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
        height: 100%;
        padding: 24px;
        position: relative;
    }
}
</style>