<template>
    <div>
        <Section class="heatmapLayoutDashboard">
            <div class="wrapHeader">
                <div class="wrapHeaderHeatmap">
                    <span class="titleHeatmap">
                    {{ $t("Report.Heatmap").toUpperCase() }}
                    </span>
                    <div class="heatmap-select-container">
                        <div
                            class="custom-button"
                            @click="clickChoosingLayout"
                            v-click-outside="clickChoosingOutLayout"
                            v-show="selectedHeatmapType.type==='heatmap-layout' && listLayout != null && listLayout.length > 0"
                        >
                            {{ selectedLayout['name'] }}
                            <span class="material-icons"> arrow_drop_down </span>
                            <div ref="wrapOptionLayout" class="wrapOption">
                                <div
                                    class="Option"
                                    v-for="layout in listLayout"
                                    :key="layout['id']"
                                    :value="layout['id']"
                                    :class="[layout.id === selectedLayout['id'] ? 'selected' : '']"
                                    @click.stop="handleSelectLayout(layout['id'])"
                                >
                                    {{ layout['name'] }}
                                </div>
                            </div>
                        </div>
                        <div
                            class="custom-button"
                            @click="handleClickDropDownChooseGroup"
                            v-click-outside="handleClickOutSideDropDownChooseGroup"
                            v-if="accountType === 'stores' && listGroup.length > 0"
                        >
                            {{ selectedGroup.name }}
                            <span class="material-icons"> arrow_drop_down </span>
                            <div
                                ref="wrapOptionChooseGroup"
                                class="wrapOption"
                            >
                                <div
                                    class="Option"
                                    v-for="group in listGroup"
                                    :key="group['id']"
                                    :value="group['id']"
                                    @click.stop="handleClickChooseGroup(group)"
                                >
                                    {{ group['name'] }}
                                </div>
                            </div>
                        </div>
                        <div
                            class="custom-button"
                            @click="handleClickDropDownChooseHeatmapType"
                            v-click-outside="handleClickOutSideDropDownChooseHeatmapType"
                            v-if="accountType === 'stores'"
                        >
                            {{ selectedHeatmapType['name'] }}
                            <span class="material-icons"> arrow_drop_down </span>
                            <div
                                ref="wrapOptionChooseHeatmapType"
                                class="wrapOption"
                            >
                                <div
                                    class="Option"
                                    v-for="heatmapType in listHeatmapType"
                                    :key="heatmapType['type']"
                                    :value="heatmapType['type']"
                                    :class="[heatmapType.type === selectedHeatmapType['type'] ? 'selected' : '']"
                                    @click.stop="handleClickChooseHeatmapType(heatmapType['type'])"
                                >
                                    {{ heatmapType['name'] }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="heatmaplayout mt-24" v-if="selectedHeatmapType['type'] === 'heatmap-layout'">
                    <span v-show="!getHeatmapLayoutStatus">{{ $t('Heatmap.HeatmapLayoutNotFound') }}</span>
                    <HeatmapLayout 
                        :heatmapData="heatmapLayoutData" 
                        :isDashboard="true"
                        v-show="getHeatmapLayoutStatus"
                    />
                </div>
                <div class="heatmaplayout mt-24" v-if="selectedHeatmapType['type'] === 'heatmap-camera'">
                    <HeatmapCameraZoneAnalysis :heatmapCameraQuery="heatmapCameraQuery"/>
                </div>
            </div>
        </Section>
    </div>
</template>


<script>
import {Api} from "@/api/index";
import * as moment from "moment-timezone";
import utils from "@/utils";
import {
    HeatmapCameraZoneAnalysis,
    HeatmapLayout
} from "@/pages";

const getListLayout = Api.get("listLayout")
const getListGroupHaveAnalysis = Api.get("getListGroupHaveAnalysis");
const getListCameraModuleHeatmap = Api.get("getListCameraModuleHeatMap");
const reportHeatmapLayout = Api.get("heatmapLayout");

export default {
    components: {
        HeatmapCameraZoneAnalysis,
        HeatmapLayout
    },
    computed: {
        getHeatmapLayoutStatus() {
            return this.heatmapLayoutData["url"] !== undefined && this.heatmapLayoutData["url"] !== ""
        }
    },
    mounted() {
        this.accountType = localStorage.accountType

        if (this.accountType === utils.ACCOUNT_TYPE_STORES) {
            this.selectedHeatmapType = this.listHeatmapType.find(
                heatmapType => heatmapType.type === utils.HEATMAP_CAMERA
            )
        }

        getListGroupHaveAnalysis.get().then((response) => {
            this.listGroup = response.data["listAnalysis"];

            if (this.listGroup != null && this.listGroup.length > 0) {
                this.selectedGroup = this.listGroup[0]
            }

            this.getListLayoutByGroup(this.selectedGroup["id"])
            if (this.selectedHeatmapType.type === utils.HEATMAP_CAMERA) {
                this.getListCameraByGroup(this.selectedGroup["id"])
            }

            this.dateRange = {
                dateStart: moment()
                    .subtract(7, "day")
                    .startOf("day")
                    .format("X"),
                dateEnd: moment()
                    .subtract(1, "day")
                    .startOf("day")
                    .format("X")
            }
        });
        setTimeout(() => {
            this.timeoutSelectGate = false
        }, 1000);
    },
    data() {
        return {
            accountType: "",
            selectedHeatmapType: "",
            listHeatmapType: [
                {
                    name: "Heatmap Layout",
                    type: utils.HEATMAP_LAYOUT
                },
                {
                    name: "Heatmap Camera",
                    type: utils.HEATMAP_CAMERA
                }
            ],
            selectedGroup: {},
            selectedLayout: {},
            listLayout: [],
            heatmapLayoutData: {},
            listCamera: [],
            dateRange: {},
            heatmapCameraQuery: {},
            listGroup: []
        }
    },
    methods: {
        handleClickChooseHeatmapType(heatmapType) {
            this.handleClickOutSideDropDownChooseHeatmapType()

            this.selectedHeatmapType = this.listHeatmapType.find(heatmap => heatmap["type"] === heatmapType)
            if (this.selectedHeatmapType.type === utils.HEATMAP_LAYOUT) {

                const dateStart = moment().startOf("day").unix();
                const dateEnd = moment().startOf("day").unix()
                reportHeatmapLayout.get(dateStart, dateEnd, this.selectedGroup["id"],
                    this.selectedLayout["id"]).then(response => {
                    this.heatmapLayoutData = response.data
                }).catch(error => {
                    if (error.response.data.message == "layout not belong to group") {
                        this.$notify({
                            timeout: 2500,
                            message: this.$t('CustomerResponse.HM0009'),
                            icon: 'add_alert',
                            horizontalAlign: 'right',
                            verticalAlign: 'top',
                            type: 'primary',
                        });
                    }
                })
            } else {
                getListCameraModuleHeatmap.get(this.selectedGroup["id"]).then(response => {
                    this.listCamera = response.data["listCamera"]
                })
            }

        },
        getHeatmapCameraQuery() {

            return {
                dateRange: this.dateRange,
                listCamera: this.listCamera
            }
        },
        clickChoosingLayout() {
            this.$refs["wrapOptionLayout"].style.display = "block";
            this.$refs["wrapOptionLayout"].style.visibility = "visible";
        },
        clickChoosingOutLayout() {
            this.$refs["wrapOptionLayout"].style.display = "none";
            this.$refs["wrapOptionLayout"].style.visibility = "hidden";
        },
        handleClickChooseGroup(group) {
            this.selectedGroup = group
            this.getListLayoutByGroup(this.selectedGroup["id"])
            this.getListCameraByGroup(this.selectedGroup["id"])

            this.$refs["wrapOptionChooseGroup"].style.display = "none";
            this.$refs["wrapOptionChooseGroup"].style.visibility = "hidden";
        },
        handleSelectLayout(layoutID) {
            // this.clickChoosingOutLayout()
            this.selectedLayout = this.listLayout.find(layout => layout["id"] === layoutID)
            this.getReportHeatmapLayout(this.selectedGroup["id"], this.selectedLayout["id"], this.dateRange.dateStart, this.dateRange.dateEnd)
        },
        handleClickOutSideDropDownChooseHeatmapType() {
            this.$refs["wrapOptionChooseHeatmapType"].style.display = "none";
            this.$refs["wrapOptionChooseHeatmapType"].style.visibility = "hidden";
        },
        handleClickOutSideDropDownChooseGroup() {
            this.$refs["wrapOptionChooseGroup"].style.display = "none";
            this.$refs["wrapOptionChooseGroup"].style.visibility = "hidden";
        },
        getListCameraByGroup(groupID) {
            getListCameraModuleHeatmap.get(groupID).then(response => {
                this.listCamera = response.data["listCamera"]
                this.heatmapCameraQuery = {
                    "listCamera": this.listCamera,
                    "dateRange": this.dateRange
                }
            })
        },
        getListLayoutByGroup(groupID) {
            getListLayout.get(groupID).then(response => {
                this.listLayout = response.data["listLayoutResponse"]
                if (this.listLayout != null && this.listLayout.length > 0) {
                    this.selectedLayout = this.listLayout[0]
                    this.getReportHeatmapLayout(groupID, this.selectedLayout["id"], this.dateRange.dateStart, this.dateRange.dateEnd)
                }
            })
        },
        getReportHeatmapLayout(groupID, layoutID, dateStart, dateEnd) {
            reportHeatmapLayout.get(dateStart, dateEnd, groupID, layoutID).then(response => {
                this.heatmapLayoutData = response.data
            }).catch(error => {
                if (error.response.data.message == "layout not belong to group") {
                    this.$notify({
                        timeout: 2500,
                        message: this.$t('CustomerResponse.HM0009'),
                        icon: 'add_alert',
                        horizontalAlign: 'right',
                        verticalAlign: 'top',
                        type: 'primary',
                    });
                }
            })
        },
        handleClickDropDownChooseHeatmapType() {
            this.$refs["wrapOptionChooseHeatmapType"].style.display = "block";
            this.$refs["wrapOptionChooseHeatmapType"].style.visibility = "visible";
        },
        handleClickDropDownChooseGroup() {
            this.$refs["wrapOptionChooseGroup"].style.display = "block";
            this.$refs["wrapOptionChooseGroup"].style.visibility = "visible";
        },
    }
}
</script>

<style lang="scss">
.heatmapLayoutDashboard {
    background: #FFFFFF;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
    width: 100%;
    height: auto;
    padding: 24px;

    .wrapHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;

        .titleHeatMap {
            font-family: 'Roboto';
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            color: #1F1F1F;
        }

        .TypeHeatmap {
            border: 1px solid #276EFF;
            border-radius: 8px;
            padding: 10px 16px;
            font-family: 'Roboto';
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #276EFF;
        }

        .wrapHeaderHeatmap {
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            width: 100%;

            .titleHeatmap {
                font-family: 'Roboto';
                font-weight: 500;
                font-size: 20px;
                line-height: 24px;
                color: #1F1F1F;
            }

            .heatmap-select-container {
                display: flex;

                .display-dropdown-choose-heatmap-type {
                    display: block;
                    visibility: visible;
                }

                .hide-dropdown-choose-heatmap-type {
                    display: none;
                    visibility: hidden;
                }
            }

            .custom-button {
                color: #276EFF;
                border-color: #276EFF;

                .wrapOption {
                    width: 100%;
                    right: 0;
                    top: 44px;

                    .Option {
                        width: auto !important;
                        color: #333333 !important;
                    }
                }
            }
        }
    }


    .heatmaplayout {
        width: 100%;

        img {
            width: 100%;
        }
    }
}
</style>